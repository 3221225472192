<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">
        <app-i18n code="user.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="user.edit.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="user.edit.title"></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>

      <el-row :gutter="10">
        <el-col :md="12" :lg="8" :xl="6">
          <el-form
            :label-position="labelPosition"
            :label-width="labelWidthForm"
            :model="model"
            :rules="rules"
            @submit.native.prevent="doSubmit"
            class="form"
            ref="form"
            v-if="model"
          >
            <el-form-item :label="fields.email.label" :prop="fields.email.name">
              <el-col>
                <el-input v-model="model[fields.email.name]" />
              </el-col>
            </el-form-item>
            <el-form-item
              :label="fields.anrede.label"
              :prop="fields.anrede.name"
              :required="fields.anrede.required"
            >
              <el-col>
                <el-select
                  placeholder="Bitte wählen Sie Anrede"
                  v-model="model[fields.anrede.name]"
                >
                  <el-option :value="undefined">--</el-option>
                  <el-option
                    :key="option.id"
                    :label="option.label"
                    :value="option.id"
                    v-for="option in fields.anrede.options"
                  ></el-option>
                </el-select>
              </el-col>
            </el-form-item>

            <el-form-item
              :label="fields.academicTitle.label"
              :prop="fields.academicTitle.name"
            >
              <el-col>
                <el-input v-model="model[fields.academicTitle.name]" />
              </el-col>
            </el-form-item>

            <el-form-item
              :label="fields.firstName.label"
              :prop="fields.firstName.name"
              :required="fields.firstName.required"
            >
              <el-col>
                <el-input v-model="model[fields.firstName.name]" />
              </el-col>
            </el-form-item>

            <el-form-item
              :label="fields.lastName.label"
              :prop="fields.lastName.name"
              :required="fields.lastName.required"
            >
              <el-col>
                <el-input v-model="model[fields.lastName.name]" />
              </el-col>
            </el-form-item>

            <el-form-item
              :label="fields.phoneNumber.label"
              :prop="fields.phoneNumber.name"
              :required="fields.phoneNumber.required"
            >
              <el-col>
                <el-input
                  prefix-icon="el-icon-fa-plus"
                  v-model="model[fields.phoneNumber.name]"
                />
              </el-col>
            </el-form-item>
            <el-form-item
              :label="fields.roles.label"
              :prop="fields.roles.name"
              :required="fields.roles.required"
            >
              <el-col>
                <el-select
                  multiple
                  placeholder
                  v-model="model[fields.roles.name]"
                >
                  <el-option
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                    v-for="option in fields.roles.options"
                  ></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item>
              <div class="form-buttons">
                <el-button
                  :disabled="saveLoading"
                  @click="doSubmit"
                  icon="el-icon-fa-floppy-o"
                  type="primary"
                >
                  <app-i18n code="common.save"></app-i18n>
                </el-button>

                <router-link :to="{ path: '/user' }">
                  <el-button :disabled="saveLoading" icon="el-icon-fa-close">
                    <app-i18n code="common.cancel"></app-i18n>
                  </el-button>
                </router-link>
              </div>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { UserModel } from '@/modules/user/user-model';
import { i18n } from '@/i18n';

const { fields } = UserModel;
const formSchema = new FormSchema([
  fields.id,
  fields.email,
  fields.anrede,
  fields.firstName,
  fields.lastName,
  fields.academicTitle,
  fields.phoneNumber,
  fields.status,
  fields.roles,
  fields.updatedAt,
]);

export default {
  name: 'app-user-edit-page',

  props: ['id'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'user/form/record',
      findLoading: 'user/form/findLoading',
      saveLoading: 'user/form/saveLoading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
    this.model = formSchema.initialValues(this.record);
  },

  methods: {
    ...mapActions({
      doFind: 'user/form/doFind',
      doUpdate: 'user/form/doUpdate',
    }),

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const values = formSchema.cast(this.model);
      this.doUpdate(values);
    },

    i18n(code) {
      return i18n(code);
    },
  },
};
</script>

<style></style>
